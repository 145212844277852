// src/assets/music/songs.js

import song1 from './Origins.mp3';
import song2 from './That Funk.mp3';
import song3 from './Choco Frogleap.mp3';
import song4 from './The Hoppers Tale.mp3';
import song5 from './Dimensionality.mp3';
import song6 from './Mellow Groove.mp3';
import song7 from './Endless Days.mp3';
import song8 from './Frog Flavours.mp3';
import song9 from './Chocolate Dreams.mp3';



export const songs = [
    { title: 'Origins', src: song1 },
    { title: 'That Funk', src: song2 },
    { title: 'Choco Frogleap', src: song3 },
    { title: 'The Hoppers Tale', src: song4 },
    { title: 'Dimensionality', src: song5 },
    { title: 'Mellow Groove', src: song6 },
    { title: 'Endless Days', src: song7 },
    { title: 'Frog Flavours', src: song8 },
    { title: 'Chocolate Dreams', src: song9 },
  ];
  
