// src/components/RandomPFPMarquee.js

import React, { useEffect, useState } from 'react';
import Marquee from 'react-fast-marquee';
import styled from 'styled-components';
import { preMadePFPs } from '../assets/PreMadePFPs';

const MarqueeContainer = styled.div`
  width: 100%;
  background-color: #f0f0f0;
  padding: 10px 0;
`;

const MarqueeItem = styled.div`
  width: 100px;
  height: 160px;
  margin: 0 10px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;

  @media(min-width: 768px) {
    width: 100px;
    height: 160px;
  }
`;

const PFPImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const RandomPFPMarquee = () => {
  const [displayPFPs, setDisplayPFPs] = useState([]);

  useEffect(() => {
    const selectRandomPFPs = () => {
      const shuffled = preMadePFPs.sort(() => 0.5 - Math.random());
      const selected = shuffled.slice(0, 50); // Select 10 random images
      setDisplayPFPs(selected);
    };

    selectRandomPFPs();
  }, []);

  return (
    <MarqueeContainer>
      <Marquee gradient={false} speed={50}>
        {displayPFPs.map((pfp, index) => (
          <MarqueeItem key={index}>
            <PFPImage src={pfp} alt={`PFP ${index + 1}`} />
          </MarqueeItem>
        ))}
      </Marquee>
    </MarqueeContainer>
  );
};

export default RandomPFPMarquee;
