const theme = {
    colors: {
      primary: '#ffcc00',
      secondary: '#ffdd57',
      accent: '#ff6600',
      background: '#ede700',
      text: '#000',
    },
    fonts: {
      primary: '"Fredoka One", cursive',
    },
  };
  
  export default theme;
  