import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const SlotMachineOverlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  border: 4px solid #000;
  border-radius: 10px;
  box-shadow: 5px 5px 0 #000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const SlotText = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 3rem;
  color: #000;
`;

const slotMachineVariants = {
  hidden: { y: '-100%' },
  visible: {
    y: '0%',
    transition: { type: 'spring', stiffness: 50 },
  },
  exit: { y: '100%', transition: { duration: 0.5 } },
};

const SlotMachine = () => (
  <SlotMachineOverlay
    variants={slotMachineVariants}
    initial="hidden"
    animate="visible"
    exit="exit"
  >
    <SlotText>Shapeshifting...</SlotText>
  </SlotMachineOverlay>
);

export default SlotMachine;
