// src/assets/preMadePFPs/index.js

import pfp1 from './pfp1.png';
import pfp2 from './pfp2.png';
import pfp3 from './pfp3.png';
import pfp4 from './pfp4.png';
import pfp5 from './pfp5.png';
import pfp6 from './pfp6.png';
import pfp7 from './pfp7.png';
import pfp8 from './pfp8.png';
import pfp9 from './pfp9.png';
import pfp10 from './pfp10.png';
import pfp11 from './pfp11.png';
import pfp12 from './pfp12.png';
import pfp13 from './pfp13.png';
import pfp14 from './pfp14.png';
import pfp15 from './pfp15.png';
import pfp16 from './pfp16.png';
import pfp17 from './pfp17.png';
import pfp18 from './pfp18.png';
import pfp19 from './pfp19.png';
import pfp20 from './pfp20.png';
import pfp21 from './pfp21.png';
import pfp22 from './pfp22.png';
import pfp23 from './pfp23.png';
import pfp24 from './pfp24.png';
import pfp25 from './pfp25.png';
import pfp26 from './pfp26.png';
import pfp27 from './pfp27.png';
import pfp28 from './pfp28.png';
import pfp29 from './pfp29.png';
import pfp30 from './pfp30.png';
import pfp31 from './pfp31.png';
import pfp32 from './pfp32.png';
import pfp33 from './pfp33.png';
import pfp34 from './pfp34.png';
import pfp35 from './pfp35.png';
import pfp36 from './pfp36.png';
import pfp37 from './pfp37.png';
import pfp38 from './pfp38.png';
import pfp39 from './pfp39.png';
import pfp40 from './pfp40.png';
import pfp41 from './pfp41.png';
import pfp42 from './pfp42.png';
import pfp43 from './pfp43.png';
import pfp44 from './pfp44.png';
import pfp45 from './pfp45.png';
import pfp46 from './pfp46.png';
import pfp47 from './pfp47.png';
import pfp48 from './pfp48.png';
import pfp49 from './pfp49.png';
import pfp50 from './pfp50.png';
import pfp51 from './pfp51.png';
import pfp52 from './pfp52.png';
import pfp53 from './pfp53.png';
import pfp54 from './pfp54.png';
import pfp55 from './pfp55.png';
import pfp56 from './pfp56.png';
import pfp57 from './pfp57.png';
import pfp58 from './pfp58.png';
import pfp59 from './pfp59.png';
import pfp60 from './pfp60.png';
import pfp61 from './pfp61.png';
import pfp62 from './pfp62.png';
import pfp63 from './pfp63.png';
import pfp64 from './pfp64.png';
import pfp65 from './pfp65.png';
import pfp66 from './pfp66.png';
import pfp67 from './pfp67.png';
import pfp68 from './pfp68.png';
import pfp69 from './pfp69.png';
import pfp70 from './pfp70.png';
import pfp71 from './pfp71.png';
import pfp72 from './pfp72.png';
import pfp73 from './pfp73.png';
import pfp74 from './pfp74.png';
import pfp75 from './pfp75.png';
import pfp76 from './pfp76.png';
import pfp77 from './pfp77.png';
import pfp78 from './pfp78.png';
import pfp79 from './pfp79.png';
import pfp80 from './pfp80.png';
import pfp81 from './pfp81.png';
import pfp82 from './pfp82.png';
import pfp83 from './pfp83.png';
import pfp84 from './pfp84.png';
import pfp85 from './pfp85.png';
import pfp86 from './pfp86.png';
import pfp87 from './pfp87.png';
import pfp88 from './pfp88.png';
import pfp89 from './pfp89.png';
import pfp90 from './pfp90.png';
import pfp91 from './pfp91.png';
import pfp92 from './pfp92.png';
import pfp93 from './pfp93.png';
import pfp94 from './pfp94.png';
import pfp95 from './pfp95.png';
import pfp96 from './pfp96.png';
import pfp97 from './pfp97.png';
import pfp98 from './pfp98.png';
import pfp99 from './pfp99.png';
import pfp100 from './pfp100.png';
import pfp101 from './pfp101.png';

// ...import as many images as you have

export const preMadePFPs = [
    pfp1, pfp2, pfp3, pfp4, pfp5, pfp6, pfp7, pfp8, pfp9, pfp10,
    pfp11, pfp12, pfp13, pfp14, pfp15, pfp16, pfp17, pfp18, pfp19, pfp20,
    pfp21, pfp22, pfp23, pfp24, pfp25, pfp26, pfp27, pfp28, pfp29, pfp30,
    pfp31, pfp32, pfp33, pfp34, pfp35, pfp36, pfp37, pfp38, pfp39, pfp40,
    pfp41, pfp42, pfp43, pfp44, pfp45, pfp46, pfp47, pfp48, pfp49, pfp50,
    pfp51, pfp52, pfp53, pfp54, pfp55, pfp56, pfp57, pfp58, pfp59, pfp60,
    pfp61, pfp62, pfp63, pfp64, pfp65, pfp66, pfp67, pfp68, pfp69, pfp70,
    pfp71, pfp72, pfp73, pfp74, pfp75, pfp76, pfp77, pfp78, pfp79, pfp80,
    pfp81, pfp82, pfp83, pfp84, pfp85, pfp86, pfp87, pfp88, pfp89, pfp90,
    pfp91, pfp92, pfp93, pfp94, pfp95, pfp96, pfp97, pfp98, pfp99, pfp100,
    pfp101,
  ];
  