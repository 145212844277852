// src/components/PFPDisplay.js

import React from 'react';
import styled from 'styled-components';

const DisplayContainer = styled.div`
  width: 300px;
  height: ${(300 * 3309) / 3308}px; /* Adjust height based on aspect ratio */
  position: relative;
  margin: 20px auto;
  border: ${(props) => (props.noBorder ? 'none' : '4px solid #000')};
  border-radius: ${(props) => (props.noBorder ? '0' : '10px')};
  box-shadow: ${(props) => (props.noShadow ? 'none' : '5px 5px 0 #000')};
  background-color: #fff;
  overflow: hidden;

  @media(min-width: 768px) {
    width: 400px;
    height: ${(400 * 3309) / 3308}px; /* Adjust height based on aspect ratio */
  }
`;


const Layer = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const PFPDisplay = ({
  background,
  accessory,
  baseBody,
  mouth,
  eyes,
  hat,
  hand, // Add hand prop
  noBorder,
  noShadow,
}) => {
  return (
    <DisplayContainer noBorder={noBorder} noShadow={noShadow} id="pfp-display">
      {background && <Layer src={background.image} alt="Background" />}
      {accessory && <Layer src={accessory.image} alt="Accessory" />}
      {hat?.isLinked && hat.backImage && (
        <Layer src={hat.backImage} alt={`${hat.name} Hat Back`} />
      )}
      {baseBody && <Layer src={baseBody.image} alt="Base Body" />}

      {eyes && <Layer src={eyes.image} alt="Eyes" />}
      {mouth && <Layer src={mouth.image} alt="Mouth" />}
      {hat && (hat.frontImage ? (
        <Layer src={hat.frontImage} alt={`${hat.name} Hat Front`} />
      ) : (
        <Layer src={hat.image} alt="Hat" />
      ))}
      {hand && <Layer src={hand.image} alt="Hand" />}
    </DisplayContainer>
  );
};

export default PFPDisplay;
