// src/components/TopMenu.js

import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { FaTwitter, FaGlobe, FaTelegramPlane } from 'react-icons/fa';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { songs } from '../assets/music/songs';

const Menu = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 30px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-bottom: 4px solid #000;
  box-shadow: 0 4px 0 #000;

  @media(min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Logo = styled.h1`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 2rem;
  margin: 0;
`;

const MenuLinks = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  @media(min-width: 768px) {
    margin-top: 0;
  }

  a {
    color: ${({ theme }) => theme.colors.text};
    font-size: 1.5rem;
    margin-left: 20px;
    text-decoration: none;
    position: relative;

    &:hover {
      color: ${({ theme }) => theme.colors.accent};
    }
  }
`;

const MusicPlayerContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin-top: 15px;

  .rhap_container {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.text};
    box-shadow: none;
    border: none;
  }

  .rhap_progress-indicator {
    background-color: ${({ theme }) => theme.colors.accent};
  }

  .rhap_progress-filled {
    background-color: ${({ theme }) => theme.colors.accent};
  }

  .rhap_main-controls-button,
  .rhap_volume-button {
    color: ${({ theme }) => theme.colors.text};
  }

  .rhap_volume-bar {
    background-color: #ccc;
  }

  .rhap_volume-indicator {
    background-color: ${({ theme }) => theme.colors.accent};
  }

  .rhap_header {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.text};
  }

  @media(min-width: 768px) {
    margin-top: 0;
  }
`;

const TopMenu = () => {
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const audioPlayer = useRef(null);

  const handleClickNext = () => {
    setCurrentSongIndex((prevIndex) =>
      prevIndex === songs.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleClickPrevious = () => {
    setCurrentSongIndex((prevIndex) =>
      prevIndex === 0 ? songs.length - 1 : prevIndex - 1
    );
  };

  return (
    <Menu>
      <Logo>YEPE PFP Generator</Logo>
      <MenuLinks>
        <a href="https://yepecoin.xyz" target="_blank" rel="noreferrer">
          <FaGlobe />
        </a>
        <a href="https://twitter.com/yepecoin" target="_blank" rel="noreferrer">
          <FaTwitter />
        </a>
        <a href="https://t.me/YepeCoinETH" target="_blank" rel="noreferrer">
          <FaTelegramPlane />
        </a>
      </MenuLinks>
      <MusicPlayerContainer>
        <AudioPlayer
          autoPlay={false}
          src={songs[currentSongIndex].src}
          onEnded={handleClickNext}
          showSkipControls={true}
          showJumpControls={false}
          onClickNext={handleClickNext}
          onClickPrevious={handleClickPrevious}
          header={`Now Playing: ${songs[currentSongIndex].title}`}
          ref={audioPlayer}
          customAdditionalControls={[]}
          customVolumeControls={[]}
        />
      </MusicPlayerContainer>
    </Menu>
  );
};

export default TopMenu;
