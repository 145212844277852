// src/components/DownloadShare.js

import React, { useContext } from 'react';
import CartoonButton from './CartoonButton';
import { TwitterShareButton } from 'react-share';
import { FaTwitter, FaDownload } from 'react-icons/fa';
import styled from 'styled-components';
import { TraitsContext } from '../context/TraitsContext';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;
`;

const DownloadShare = () => {
  const {
    selectedBackground,
    selectedAccessory,
    selectedBaseBody,
    selectedMouth,
    selectedEyes,
    selectedHat,
    selectedHand, // Destructure selectedHand
  } = useContext(TraitsContext);

  const downloadImage = async () => {
    const width = 3308; // Update width to match your new image resolution
    const height = 3309; // Update height to match your new image resolution

    // Create a new canvas element
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    // Function to load an image
    const loadImage = (src) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'anonymous'; // To avoid CORS issues
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error('Failed to load image: ' + src));
      });

    try {
      // Load images
      const bg = await loadImage(selectedBackground.image);
      const acc = await loadImage(selectedAccessory.image);
      const body = await loadImage(selectedBaseBody.image);
      const mouth = await loadImage(selectedMouth.image);
      const eyesImg = await loadImage(selectedEyes.image);
      const handImg = await loadImage(selectedHand.image); // Load hand image

      // Draw images in the correct order
      ctx.drawImage(bg, 0, 0, width, height);
      ctx.drawImage(acc, 0, 0, width, height);

      if (selectedHat.isLinked && selectedHat.backImage) {
        const hatBack = await loadImage(selectedHat.backImage);
        ctx.drawImage(hatBack, 0, 0, width, height);
      }

      ctx.drawImage(body, 0, 0, width, height);

      // Draw hand image
      ctx.drawImage(handImg, 0, 0, width, height);

      ctx.drawImage(mouth, 0, 0, width, height);
      ctx.drawImage(eyesImg, 0, 0, width, height);

      if (selectedHat.frontImage) {
        const hatFront = await loadImage(selectedHat.frontImage);
        ctx.drawImage(hatFront, 0, 0, width, height);
      } else if (selectedHat.image) {
        const hat = await loadImage(selectedHat.image);
        ctx.drawImage(hat, 0, 0, width, height);
      }

      // Convert canvas to data URL
      const dataURL = canvas.toDataURL('image/png');

      // Create a link and trigger download
      const link = document.createElement('a');
      link.download = 'my_pfp.png';
      link.href = dataURL;
      link.click();
    } catch (error) {
      console.error('Error downloading image:', error);
      alert('Failed to download image. Please try again.');
    }
  };

  // Share URLs
  const shareUrl = 'https://yepecoin.xyz/';
  const shareMessage = 'Create your own FURIE PFP!';

  return (
    <ButtonContainer>
      <CartoonButton onClick={downloadImage}>
        <FaDownload /> Download
      </CartoonButton>
      <TwitterShareButton url={shareUrl} title={shareMessage}>
        <CartoonButton>
          <FaTwitter /> Tweet
        </CartoonButton>
      </TwitterShareButton>
    </ButtonContainer>
  );
};

export default DownloadShare;
