// src/components/TraitSelector.js

import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { TraitsContext } from '../context/TraitsContext';
import {
  backgrounds,
  accessories,
  baseBodies,
  mouths,
  eyes,
  hats,
  hands,
} from '../assets/traits';

// src/components/TraitSelector.js

const TabsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px;
`;

const Tab = styled.button`
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.secondary : theme.colors.primary};
  border: 2px solid #000;
  border-bottom: ${({ isActive }) => (isActive ? 'none' : '2px solid #000')};
  border-radius: 8px 8px 0 0;
  padding: 10px 20px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.1rem;
  margin: 0 5px;
  outline: none;
  position: relative;
  top: 2px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accent};
  }
`;


const TraitList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const TraitButton = styled.button`
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.secondary : theme.colors.primary};
  color: ${({ theme }) => theme.colors.text};
  border: 2px solid #000;
  border-radius: 8px;
  padding: 8px 12px;
  font-family: ${({ theme }) => theme.fonts.primary};
  cursor: pointer;
  transition: background-color 0.2s;
  min-width: 80px;
  text-align: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accent};
  }
`;

const TraitSelector = () => {
  const {
    selectedBackground,
    setSelectedBackground,
    selectedAccessory,
    setSelectedAccessory,
    selectedBaseBody,
    setSelectedBaseBody,
    selectedMouth,
    setSelectedMouth,
    selectedEyes,
    setSelectedEyes,
    selectedHat,
    setSelectedHat,
    selectedHand,
    setSelectedHand,
  } = useContext(TraitsContext);

  const traitCategories = [
    {
      name: 'Backgrounds',
      traits: backgrounds,
      selectedTrait: selectedBackground,
      setSelectedTrait: setSelectedBackground,
    },
    {
      name: 'Accessories',
      traits: accessories,
      selectedTrait: selectedAccessory,
      setSelectedTrait: setSelectedAccessory,
    },
    {
      name: 'Base Bodies',
      traits: baseBodies,
      selectedTrait: selectedBaseBody,
      setSelectedTrait: setSelectedBaseBody,
    },
    {
      name: 'Mouths',
      traits: mouths,
      selectedTrait: selectedMouth,
      setSelectedTrait: setSelectedMouth,
    },
    {
      name: 'Eyes',
      traits: eyes,
      selectedTrait: selectedEyes,
      setSelectedTrait: setSelectedEyes,
    },
    {
      name: 'Hats',
      traits: hats,
      selectedTrait: selectedHat,
      setSelectedTrait: setSelectedHat,
    },
    {
      name: 'Hands',
      traits: hands,
      selectedTrait: selectedHand,
      setSelectedTrait: setSelectedHand,
    },
  ];

  const [activeTab, setActiveTab] = useState(traitCategories[0].name);

  const currentCategory = traitCategories.find(
    (category) => category.name === activeTab
  );

  return (
    <div>
      <TabsContainer>
        {traitCategories.map((category) => (
          <Tab
            key={category.name}
            onClick={() => setActiveTab(category.name)}
            isActive={activeTab === category.name}
          >
            {category.name}
          </Tab>
        ))}
      </TabsContainer>
      <TraitList>
        {currentCategory.traits.map((trait) => (
          <TraitButton
            key={trait.name}
            onClick={() => currentCategory.setSelectedTrait(trait)}
            selected={currentCategory.selectedTrait.name === trait.name}
          >
            {trait.name}
          </TraitButton>
        ))}
      </TraitList>
    </div>
  );
};

export default TraitSelector;
