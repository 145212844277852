// src/components/App.js

import React, { useContext, useState } from 'react';
import TopMenu from './TopMenu';
import TraitSelector from './TraitSelector';
import PFPDisplay from './PFPDisplay';
import CartoonButton from './CartoonButton';
import RandomPFPMarquee from './RandomPFPMarquee';
import DownloadShare from './Downloadshare';
import SlotMachine from './SlotMachine';
import styled from 'styled-components';
import { TraitsContext } from '../context/TraitsContext';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  @media(min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 40px;
  }
`;

const PFPSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`;

const TraitSelectorCard = styled.div`
  width: 100%;
  margin-top: 20px;

  @media(min-width: 768px) {
    width: 400px;
    margin-left: 40px;
    margin-top: 0;
  }

  background-color: #fff;
  border: 4px solid #000;
  border-radius: 12px;
  box-shadow: 5px 5px 0 #000;
  padding: 20px;
`;

const App = () => {
  const {
    selectedBackground,
    selectedAccessory,
    selectedBaseBody,
    selectedMouth,
    selectedEyes,
    selectedHat,
    selectedHand,
    randomizeTraits,
    resetTraits,
  } = useContext(TraitsContext);

  const [randomizing, setRandomizing] = useState(false);

  const handleRandomize = () => {
    setRandomizing(true);
    setTimeout(() => {
      randomizeTraits();
      setRandomizing(false);
    }, 2000);
  };

  const handleReset = () => {
    resetTraits();
  };

  return (
    <>
      <TopMenu />
      <RandomPFPMarquee />
      <AppContainer>
        <PFPSection>
          <PFPDisplay
            background={selectedBackground}
            accessory={selectedAccessory}
            baseBody={selectedBaseBody}
            mouth={selectedMouth}
            eyes={selectedEyes}
            hat={selectedHat}
            hand={selectedHand}
          />
          {randomizing && <SlotMachine />}
          <ControlsContainer>
            <CartoonButton onClick={handleRandomize}>🎰 Shuffle</CartoonButton>
            <CartoonButton onClick={handleReset}>🔄 Reset</CartoonButton>
            <DownloadShare />
          </ControlsContainer>
        </PFPSection>
        <TraitSelectorCard>
          <TraitSelector />
        </TraitSelectorCard>
      </AppContainer>
    </>
  );
};

export default App;
